const AUTH_TOKEN_KEY = "@limbic-for-therapists/auth-token";
const REFRESH_TOKEN_KEY = "@limbic-for-therapists/refresh-token";

const getAuthToken = () => {
  if (!window) return null;
  return window.localStorage.getItem(AUTH_TOKEN_KEY);
};

const getRefreshToken = () => {
  if (!window) return null;
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

const setAuthToken = (authToken: string | undefined) => {
  if (!authToken) return window.localStorage.removeItem(AUTH_TOKEN_KEY);
  return window.localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

const setRefreshToken = (refreshToken: string | undefined) => {
  if (!refreshToken) return window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  return window.localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refreshToken));
};

export { getAuthToken, getRefreshToken, setAuthToken, setRefreshToken };
