import { ApolloClient, InMemoryCache, createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "./helpers/authToken";
import dayjs from "dayjs";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import config from "../config";
import { Region } from "../data/auth/region";

export function createTitanClient(region: Region) {
  if (!config.regions.has(region)) {
    throw new Error(
      `Region ${region} is not supported. Supported regions are: ${Array.from(config.regions).join(
        ", ",
      )}`,
    );
  }

  const titanCache = new InMemoryCache({
    typePolicies: {
      ISOInterval: {
        fields: {
          iso8601Duration: {
            read(value: string) {
              return dayjs.duration(value);
            },
          },
        },
      },
    },
  });

  const titanHttpLink = createHttpLink({
    uri: config.titan[region].titanEndpoint,
  });

  const titanAuthLink = setContext((_, { headers }) => {
    const token = getAuthToken();
    return {
      headers: {
        ...headers,
        ...(token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}),
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: config.titan[region].titanWsEndpoint,
      connectionParams: () => {
        const token = getAuthToken();
        return {
          Authorization: token ? `Bearer ${token}` : "",
        };
      },
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    titanAuthLink.concat(titanHttpLink),
  );

  return new ApolloClient({
    name: "New Care Backend Titan Server",
    link: splitLink,
    cache: titanCache,
  });
}
