import React from "react";
import { EmptyState } from "../EmptyState";
import { IoAlertCircle } from "react-icons/io5";

interface ErrorStateArgs {
  error: Error;
}

export function ErrorState({ error }: ErrorStateArgs) {
  return (
    <EmptyState
      customIcon={<IoAlertCircle size={100} name="alert-circle" className="text-pink2" />}
      title="We had an unexpected error"
      text={error.message}
      bottomAccessory={
        <div className="mt-6 flex flex-col items-center gap-2">
          <p className="text-sm text-darkGrey1">
            If the error persists please contact our support.
          </p>
        </div>
      }
    />
  );
}
