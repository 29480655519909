import { useCallback } from "react";
import { IClinician } from "src/data/models/IClinician";

type AnalyticEventName =
  | "dashboard-toggled-tab"
  | "user-logged-in"
  | "user-registered"
  | "patient-viewed"
  | "change-session-date"
  | "add-coping-strategy"
  | "schedule-behavioural-activity"
  | "schedule-questionnaire"
  | "invite-patient-started"
  | "invite-patient"
  | "user-reset-password"
  | "add-custom-intervention"
  | "worksheets-homework-clicked"
  | "behavioural-activity-homework-clicked"
  | "coping-strategy-homework-clicked"
  | "questionnaire-homework-clicked"
  | "export-patient-clicked";

interface AnalyticBase<T extends AnalyticEventName, Q = undefined> {
  eventName: T;
  params?: Q;
}

type AnalyticEvent = [
  AnalyticBase<"dashboard-toggled-tab", { tab: any }>,
  AnalyticBase<"user-logged-in", { userId: string | null }>,
  AnalyticBase<"user-registered", { userId: string | null }>,
  AnalyticBase<"patient-viewed", { patientId: string }>,
  AnalyticBase<"add-coping-strategy", { patientId: string; emotion: string }>,
  AnalyticBase<"change-session-date", { patientId: string; toDate: Date }>,
  AnalyticBase<"schedule-questionnaire", { patientId: string; types: string[] }>,
  AnalyticBase<"invite-patient-started">,
  AnalyticBase<
    "invite-patient",
    {
      patientId: string;
      questionnaireTypes?: string[];
      name?: string;
      email?: string;
      phone?: string | null;
    }
  >,
  AnalyticBase<"user-reset-password">,
  AnalyticBase<"add-custom-intervention", { patientId: string }>,
  AnalyticBase<"worksheets-homework-clicked", { patientId: string }>,
  AnalyticBase<"behavioural-activity-homework-clicked", { patientId: string }>,
  AnalyticBase<"coping-strategy-homework-clicked", { patientId: string }>,
  AnalyticBase<"questionnaire-homework-clicked", { patientId: string }>,
  AnalyticBase<"export-patient-clicked", { patientId: string }>,
];

const useAnalytics = (mixpanel: any) => {
  const logAnalytic = useCallback(
    (event: AnalyticEvent[number]) => {
      if (!mixpanel) {
        console.log("Mixpanel unavailable while tracking", event.eventName, event.params);
        return;
      }
      try {
        mixpanel.track(event.eventName, event.params);
      } catch (e) {
        console.log("Error tracking event", event.eventName, event.params);
      }
    },
    [mixpanel],
  );

  const setUserProperties = useCallback(
    (clinician: IClinician) => {
      if (!mixpanel) return;
      try {
        mixpanel.identify(clinician.id);
        mixpanel.people.set({
          email: clinician.email,
          name: clinician.name,
          clinicId: clinician.clinic?.id,
          clinicName: clinician.clinic?.name,
        });
      } catch (e) {}
    },
    [mixpanel],
  );

  return { logAnalytic, setUserProperties };
};

export { useAnalytics };
