export interface DimensionType {
  width: number;
  height: number;
}

export const grid = {
  minWidth: 600,
  maxWidth: 1200,
  breakpoints: [768, 998, 1200],
};

export type ResponsiveSize = "xs" | "sm" | "md" | "lg";
