import React, { useState, createContext, useEffect, useCallback } from "react";
import { ModalContainer } from "src/components/TherapistGenericModal/ModalContainer";
import { FeatureFlagsFilterProvider } from "./FeatureFlagsContext";

type CustomDimensions = {
  minWidth?: number | string;
  width?: number | string;
  height?: number | string;
};

interface ModalContextProps {
  children?: React.ReactNode;
  onContentChanged?: (hasContent: boolean) => void;
}

const ModalContext = createContext<{
  content?: React.ReactNode;
  setContent: (
    content?: React.ReactNode,
    customDimensions?: CustomDimensions,
    showCloseButton?: boolean,
    closeOnBackgroundClick?: boolean,
  ) => void;
  dismissModal: () => void;
}>({
  content: undefined,
  setContent: () => {},
  dismissModal: () => {},
});

export const ModalContextProvider = ({ children, onContentChanged }: ModalContextProps) => {
  const [content, setContent] = useState<React.ReactNode | undefined>();
  const [showCloseButton, setShowCloseButton] = useState<boolean>(true);
  const [customDimensions, setCustomDimensions] = useState<CustomDimensions | undefined>();

  const [internalShowCloseButton, setInternalShowCloseButton] = useState<boolean>(true);
  const [internalCustomDimensions, setInternalCustomDimensions] = useState<
    CustomDimensions | undefined
  >();

  const handleSetContent = useCallback(
    (
      content?: React.ReactNode,
      customDimensions?: CustomDimensions | undefined,
      showCloseButton?: boolean,
    ) => {
      setContent(content);
      setCustomDimensions(customDimensions);
      setShowCloseButton(typeof showCloseButton === "undefined" ? true : showCloseButton);
    },
    [],
  );

  useEffect(() => {
    (async () => {
      setInternalShowCloseButton(showCloseButton);
      setInternalCustomDimensions(customDimensions);
    })();
    if (onContentChanged) onContentChanged(!!content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, onContentChanged]);

  const values = {
    content,
    setContent: handleSetContent,
    dismissModal: () => {
      handleSetContent(undefined);
    },
  };

  return (
    <ModalContext.Provider value={values}>
      <FeatureFlagsFilterProvider>
        {children}
        <ModalContainer
          dismissModal={() => handleSetContent(undefined)}
          showCloseButton={internalShowCloseButton}
          customWidth={internalCustomDimensions?.width}>
          {content}
        </ModalContainer>
      </FeatureFlagsFilterProvider>
    </ModalContext.Provider>
  );
};
