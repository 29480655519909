import { z } from "zod";
import { Region } from "./data/auth/region";
import { assertNotNull } from "./utils";

const regions = z
  .array(z.nativeEnum(Region))
  .safeParse(process.env.NEXT_PUBLIC_REGIONS?.split(","));
if (!regions.success) {
  throw new Error(
    `NEXT_PUBLIC_REGIONS env variable is mandatory and should be a comma-separated list of regions. Error: ${regions.error}`,
  );
}

const titanHttpEndpoints = z
  .object(Object.fromEntries(regions.data.map((region) => [region, z.string().url()])))
  .safeParse(JSON.parse(process.env.NEXT_PUBLIC_TITAN_ENDPOINTS ?? ""));

if (!titanHttpEndpoints.success) {
  throw new Error(
    `NEXT_PUBLIC_TITAN_ENDPOINTS must be a json object with keys as regions and values as urls. Error: ${titanHttpEndpoints.error}`,
  );
}

const titanWsEndpoints = z
  .object(Object.fromEntries(regions.data.map((region) => [region, z.string().url()])))
  .safeParse(JSON.parse(process.env.NEXT_PUBLIC_WS_TITAN_ENDPOINTS ?? ""));

if (!titanWsEndpoints.success) {
  throw new Error(
    `NEXT_PUBLIC_WS_TITAN_ENDPOINT must be a json object with keys as regions and values as urls. Error: ${titanWsEndpoints.error}`,
  );
}

const config = {
  stage: process.env.NODE_ENV || "development",
  regions: new Set(regions.data),
  titan: Object.fromEntries(
    regions.data.map((region) => [
      region,
      {
        titanEndpoint: assertNotNull(
          titanHttpEndpoints.data[region],
          `NEXT_PUBLIC_TITAN_ENDPOINTS must define an endpoint for region ${region}`,
        ),
        titanWsEndpoint: assertNotNull(
          titanWsEndpoints.data[region],
          `NEXT_PUBLIC_WS_TITAN_ENDPOINT must define an endpoint for region ${region}`,
        ),
      },
    ]),
  ),
  sentrySecret: assertNotNull(
    process.env.NEXT_PUBLIC_SENTRY_SECRET,
    "NEXT_PUBLIC_SENTRY_SECRET env variable is mandatory",
  ),
  IS_SIGNUP_ENABLED: false,
  ARE_REMINDERS_ENABLED: true,
  REFRESH_TOKEN_MAX_AGE: 60 * 60 * 24 * 30, // 30 days
  mixpanelToken: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
};

export default config;
