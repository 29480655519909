import { gql, useQuery } from "@apollo/client";
import { IClinician } from "../models/IClinician";

const GET_CLINICIAN = gql`
  query getClinician {
    currentClinician {
      id
      name
      email
      clinic {
        id
        name
      }
    }
  }
`;

interface IGetClinicianClinician extends Pick<IClinician, "id" | "name" | "email"> {
  clinic: {
    id: string;
    name: string;
  };
}

export interface IGetClinicianResponse {
  currentClinician: IGetClinicianClinician;
}

export const useGetClinician = () => {
  const { data, ...rest } = useQuery<IGetClinicianResponse>(GET_CLINICIAN);

  return {
    clinician: data?.currentClinician,
    ...rest,
  };
};
