import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createTitanClient } from "../client";
import { Region } from "../../data/auth/region";

const _clients = new Map<Region, ApolloClient<NormalizedCacheObject>>();

export function getTitanApolloClient(region: Region): ApolloClient<NormalizedCacheObject> {
  if (!_clients.has(region)) {
    _clients.set(region, createTitanClient(region));
  }

  const client = _clients.get(region);
  if (!client) {
    throw new Error("Unreachable code: client must be defined at this point");
  }

  return client;
}
