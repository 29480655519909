import { noop } from "lodash";
import { createContext, useCallback, useContext, useEffect } from "react";
import { setAuthToken, setRefreshToken } from "src/backend/helpers/authToken";
import { featureFlagOptions } from "src/consts/featureFlags";
import { useGetClinicFeatureFlags } from "src/data/queries/useGetClinicFeatureFlags";
import { IGetClinicianResponse, useGetClinician } from "src/data/queries/useGetClinician";
import { useAnalytics } from "src/hooks/useAnalytics";
import mixpanel from "mixpanel-browser";
import { useIntercom } from "react-use-intercom";
import config from "../config";
import { useRouter } from "next/router";
import { RegionContext } from "../data/auth/region";
import { getTitanApolloClient } from "../backend/helpers/getApolloClient";

const ActiveClinicianContext = createContext<{
  clinician: IGetClinicianResponse["currentClinician"] | undefined;
  logOut: () => void;
}>({
  clinician: undefined,
  logOut: noop,
});

export const ActiveClinicianContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { clinician } = useGetClinician();
  const { featureFlags } = useGetClinicFeatureFlags(clinician?.clinic.id || -1);
  const { setUserProperties } = useAnalytics(mixpanel);
  const { shutdown: shutdownIntercom, boot } = useIntercom();
  const router = useRouter();
  const { region } = useContext(RegionContext);

  useEffect(() => {
    if (clinician) {
      setUserProperties(clinician);
    }
  });

  const logOut = useCallback(async () => {
    setAuthToken(undefined);
    setRefreshToken(undefined);
    shutdownIntercom();
    const client = getTitanApolloClient(region);
    await client.clearStore();
    router.push("/login");
  }, [router, shutdownIntercom, region]);

  useEffect(() => {
    if (clinician && featureFlags?.includes(featureFlagOptions.ENABLE_INTERCOM_THERAPIST_APP)) {
      boot({
        userId: clinician.id,
        name: `${clinician.name} (Clinician)`,
        email: clinician.email,
        hideDefaultLauncher: !featureFlags?.includes(
          featureFlagOptions.SHOW_INTERCOM_THERAPIST_APP,
        ),
        horizontalPadding: 120,
        verticalPadding: 40,
        customAttributes: {
          user_type: "clinician",
          user_clinic: clinician.clinic.name,
          user_clinicId: clinician.clinic.id,
          environment: config.stage,
        },
      });
    }
  }, [clinician, boot, featureFlags]);

  return (
    <ActiveClinicianContext.Provider value={{ clinician, logOut }}>
      {children}
    </ActiveClinicianContext.Provider>
  );
};

export const useActiveClinicianContext = () => useContext(ActiveClinicianContext);
