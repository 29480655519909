import React, { useEffect, useMemo } from "react";
import { FeatureFlags, getFeatureFlags } from "src/consts/featureFlags";
import { useGetClinicById } from "src/data/queries/useGetClinicById";
import { useActiveClinicianContext } from "./ActiveClinicianContext";

interface FeatureFlagsFilterContextProps {
  featureFlags: FeatureFlags;
  loading: boolean;
}

const ctx = React.createContext<FeatureFlagsFilterContextProps | null>(null);

export const FeatureFlagsFilterProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const { clinician } = useActiveClinicianContext();
  const { data: clinicResponse, loading } = useGetClinicById(clinician?.clinic.id);

  const featureFlags = useMemo(
    () => getFeatureFlags(clinicResponse?.clinic.featureFlags),
    [clinicResponse?.clinic.featureFlags],
  );

  useEffect(() => {
    // Adding featureFlags to the window element for Cypress testing
    if (typeof window !== "undefined") {
      (window as any).featureFlags = featureFlags;
    }
  }, [featureFlags]);

  const value: FeatureFlagsFilterContextProps = { featureFlags, loading };
  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};
