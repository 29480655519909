import { createContext, useEffect, useState } from "react";
import { z } from "zod";

const REGION_KEY = "@limbic-for-therapists/region";

export enum Region {
  EU = "EU",
  US = "US",
}

type RegionContextType = {
  region: Region;
  setRegion: (region: Region) => void;
};

export const RegionContext = createContext<RegionContextType>({
  region: Region.EU,
  setRegion: () => {},
});

const RegionParser = z.nativeEnum(Region);

export function RegionContextProvider({ children }: React.PropsWithChildren<unknown>) {
  // initial rendering is done in ssr
  const [region, setRegion] = useState(Region.EU);

  useEffect(() => {
    // lookup the region from local storage in the browser
    const savedRegion = localStorage.getItem(REGION_KEY);
    const parsedRegion = RegionParser.safeParse(savedRegion);
    if (parsedRegion.success) {
      setRegion(parsedRegion.data);
    }
  }, []);

  const foo = {
    region,
    setRegion: (region: Region) => {
      // set the region in local storage
      localStorage.setItem(REGION_KEY, region);
      setRegion(region);
    },
  };

  return <RegionContext.Provider value={foo}>{children}</RegionContext.Provider>;
}
